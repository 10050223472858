@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";

.pricing-motion {
    overflow: hidden;
    padding: 30px 24px 0;
    max-width: 743px;

    .text {
        margin-top: 32px;
        font-size: 20px;
        font-family: "TTNormsPro-Bold", $global-font-family;
        text-align: center;
        color: $teal;
        opacity: 0;

        @media (min-width: 640px) {
            &.left {
                text-align: left;
                padding-left: 28px;
            }
            &.right {
                text-align: right;
                padding-right: 28px;
            }
        }
    }

    @media (min-width: 640px) {
        display: flex;
        padding-top: 124px;
    }


    .pricing-fixed,
    .pricing-minute {
        width: 91.7%;
        margin: 0 auto;

        .line {
            position: relative;
            width: 100%;
            height: 40px;
            padding: 10px 0;
        }

        .grow-line {
            position: absolute;
            top: 0;
            left: 0;
            right: calc(100% - 60px);
            height: 60px;
            border-radius: 30px;
            background-color: rgba(255, 255, 255, .25);

            &::after {
                content: '';
                position: absolute;
                bottom: calc(100% + 16px);
                right: 0.5%;
                width: 67px;
                height: 72px;
                background: url('~assets/img/p3/pin.svg') center no-repeat;
            }
        }

        @media (min-width: 640px) {
            width: 50%;
        }
    }

    .pricing-fixed {
        .solid-line {
            width: calc(100% - 10px);
            margin-left: 10px;
            height: 40px;
            border-radius: 20px;
            background-color: white;
        }
    }

    .pricing-minute {
        position: relative;
        margin-top: 120px;

        .grow-line {
            left: -100%;
            right: 200%;
        }

        .dot {
            position: absolute;
            top: 20px;
            width: 20px;
            height: 20px;
            background-color: $teal;
            border-radius: 10px;
            transform: scale(0.1);
            opacity: 0;

            &:nth-child(1) {
                right: calc(28px + 80%);
            }

            &:nth-child(2) {
                right: calc(28px + 64%);
            }

            &:nth-child(3) {
                right: calc(28px + 48%);
            }

            &:nth-child(4) {
                right: calc(28px + 32%);
            }

            &:nth-child(5) {
                right: calc(28px + 16%);
            }

            &:nth-child(6) {
                right: 28px;
            }
        }

        @media (min-width: 640px) {
            margin-top: 0;

            .grow-line {
                display: none;
            }
        }
    }

    &.active {
        .pricing-fixed {
            .grow-line {
                animation: line-grow 4s ease-in-out forwards;
            }

            @media (min-width: 640px) {
                .grow-line {
                    animation-duration: 5s;
                }
            }
            .text {
                animation: fade-in 1s 1.5s ease-in-out forwards;
            }
        }

        .pricing-minute {
            .grow-line {
                animation: line-grow-mob 5s ease-in-out forwards;
            }

            .dot {
                animation: dot-grow .5s forwards;

                &:nth-child(1) {
                    animation-delay: 2.8s;
                }

                &:nth-child(2) {
                    animation-delay: 3.0s;
                }

                &:nth-child(3) {
                    animation-delay: 3.3s;
                }

                &:nth-child(4) {
                    animation-delay: 3.6s;
                }

                &:nth-child(5) {
                    animation-delay: 4.0s;
                }

                &:nth-child(6) {
                    animation-delay: 4.4s;
                }
            }
            .text {
                animation: fade-in 1s 3.5s ease-in-out forwards;
            }
        }
    }

    &.type-dots {
        .text {
            color: $yellow;
        }

        .pricing-fixed,
        .pricing-minute {
            .dot {
                background-color: $yellow;
            }

            .grow-line {

                &::after {
                   background: url('~assets/img/p3/pin-dots.svg') center no-repeat;
                }
            }
        }
        .pricing-fixed {
            .solid-line {
                background-color: $yellow;
                position: absolute;
                z-index: 1;

                @media (min-width: 640px) {
                    width: calc(133% - 10px);

                }
            }
        }
    }
}

@keyframes line-grow {
    0% {
        right: calc(100% - 60px);
    }

    100% {
        right: -100%;
    }
}

@keyframes line-grow-mob {
    0% {
        right: 200%;
    }

    100% {
        right: 0%;
    }
}

@keyframes dot-grow {
    0% {
        opacity: 1;
        transform: scale(0.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
