@import "../../scss/setup/variable.scss";

.container {
    box-sizing: border-box;
    position: relative;
    max-width: 480px;
    margin: 0 auto;
    padding: 134px 0 96px;
    text-align: center;
}

.congratulations {
    width: 100%;
    padding-top: 65%;
    margin: 0 auto;
    background: url('~assets/img/done.svg') center/contain no-repeat;
}

.message-header {
    width: 100%;
    padding-top: 65%;
    margin: 0 auto;
    background: url('~assets/img/wrong.svg') center/contain no-repeat;
}

.message {
    font-size: 16px;
    line-height: 24px;
    color: #3A3F52;
    display: block;
    margin: 16px 0;
}

.eng-message {
    padding: 0 15px;
    color: #3A3F52;
}
.eng-message small{
    font-size: 14px;
}
