$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

@mixin xs() {
    @media (max-width: $screen-xs) {
        @content;
    }
}

@mixin sm() {
    @media (max-width: $screen-sm) {
        @content;
    }
}

@mixin md() {
    @media (max-width: $screen-md) {
        @content;
    }
}

@mixin lg() {
    @media (max-width: $screen-lg) {
        @content;
    }
}

@mixin portrait() {
    @media screen and (orientation:portrait) {
        @content;
    }
}

@mixin landscape() {
    @media screen and (orientation:landscape) {
        @content;
    }
}
