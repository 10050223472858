@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/flex-center.scss";
@import "../../scss/mixins/media-query.scss";

.lost-items {
    background: $smoke-gray;

    .content {
        width: 784px;
        max-width: 90%;
        margin: 0 auto;
        padding: 0 0 80px 0;

        @include xs() {
            padding: 0 24px 80px;
            max-width: 100%;
        }
    }

    .description {
        color: #173e6f;
        font-size: 18px;
        margin: 0 0 16px 0;
        padding: 0 32px;

        @include xs() {
            padding: 0;
        }

        .line {
            line-height: 1.5;
        }
    }

    .notice {
        color: #173e6f;
        font-size: 13px;
        line-height: 1.5;
        margin: 0 0 40px 0;
        padding: 0 32px;

        @include xs() {
            padding: 0;
        }
    }

    .lost-item-list {
        background: #FFF;
        border-radius: 10px;
        text-align: left;
        padding: 24px 32px;
        overflow: auto;
        max-height: 551px;
        width: 100%;

        table {
            border-collapse: collapse;
            width: 100%;
        }

        th {
            color: #09ccd9;
            padding: 12px;
        }

        td {
            color: #323237;
            padding: 12px 5px 12px 12px;
            white-space: nowrap;
            width: 25%;
        }

        thead tr {
            border-bottom: 1px #ededed solid;
        }

        .item:hover {
            background: #f9f9f9;
        }

        .item-description {
            display: inline-block;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: unset;
        }
    }
}
