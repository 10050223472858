@import "../../../scss/mixins/animation.scss";
@import "../../../scss/mixins/media-query.scss";

@include fade-out();
@include zoom-in();
@include stroke-line();
@include dot-wave();

.map-animation {
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(1.5);
    animation: zoom-in 2s 1.5s ease-in-out forwards;

    @include sm() {
        top: 80px;
    }

    .worldmap {
        position: absolute;
        width: 1969px;
        height: 945px;
        top: 50%;
        left: 50%;
        opacity: 0.1;
        transform: translate(-37.5%, -56.5%);
    }

    .dot {
        position: absolute;
        width: 4px;
        height: 4px;

        &.dot-taiwan {
            top: 50%;
            left: 50%;
            transform: translate(155%, -1600%);
        }

        &.dot-jakarta {
            top: 50%;
            left: 50%;
            transform: translate(-1800%, 2700%);
        }

        &.dot-singapore {
            top: 50%;
            left: 50%;
            transform: translate(-2250%, 1650%);
        }

        &.dot-philippines {
            top: 50%;
            left: 50%;
            transform: translate(50%, -150%);
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            background-color: rgba(6, 243, 249, .3);
            animation: dot-wave 5s ease-out infinite;

            &:nth-child(2) {
                animation-delay: .5s;
            }
            &:nth-child(3) {
                animation-delay: 1s;
            }
        }
    }
    .location {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 191px;
        height: 185px;
        background: url('~assets/img/map-animation/location.svg') center/contain no-repeat;
        transform: translate(-41.5%, -37.5%);
        animation: fade-out 1s 1s ease-in-out forwards;
    }
    .path {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 176px;
        opacity: 0.3;
        transform: translate(-90%, -36.75%);

        svg path {
            stroke-dasharray: 200;
            stroke-dashoffset: 200;
            animation: stroke-line 2.5s ease-in-out;
            animation-iteration-count: 4;
        }
    }
}
