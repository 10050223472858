@import "../../../scss/setup/variable.scss";
@import "../../../scss/mixins/media-query.scss";
@import "../../../scss/mixins/flex-center.scss";

.go-everywhere {
    background: $green;
    background: var(--green);
    position: relative;

    &::before {
        background: $green;
        background: var(--green);
    }

    .caption {
        margin: 0 0 20px;
        color: var(--blue-dark);

        @include sm() {
            margin-bottom: 14px;
        }
    }

    .content {
        line-height: 1.4;
        margin: 0 0 12px 0;
        color: var(--blue-dark);

        @include sm() {
            font-size: 14px;
        }

        &.notice {
            font-size: 16px;
            opacity: .5;

            @include sm() {
                font-size: 12px;
            }
        }
        &.withLink {
            margin-bottom: 20px;
        }

        &:last-child {
            margin: 0;
        }

        .link {
            font-family: "TTNormsPro-Medium", "PingFangTC-Medium", $global-font-family;
            font-size: 20px;
            color: var(--blue-dark);
            text-decoration: underline;
            display: inline-block;
            font-weight: 500;

            @include sm() {
                font-size: 14px;
            }
        }
    }

    .wrapper {
        display: flex;
        padding-bottom: 526px;
        flex-direction: column;

        @include lg() {
            padding-bottom: 496px;
        }

        @include sm() {
            padding-bottom: 0;
            overflow: hidden;
        }

    }

    .row {
        display: flex;

        &-first {

            @include sm() {
                margin-bottom: 0;
            }
        }
        &-second {
            position: relative;

            .description {
                position: relative;

                margin-top: 306px;

                @include lg() {
                    margin-top: 206px;
                }

                @include md() {
                    margin-top: 126px;
                }

                @include sm() {
                    margin-top: 0px;
                }
            }
        }
        @include sm() {
            flex-direction: column;
            padding-bottom: 80px;
            align-items: center;
        }
    }

    .col {
        width: 50%;

        @include sm() {
            width: 100%;
        }

        &.pipe {
            width: 60%;
        }

        @include md() {
            &.pipe {
                width: 90%;
                margin: auto;
                margin-right: auto;
            }
        }

        &.dots {
            width: 100vw;
            max-height: 861px;
            max-width: 1440px;
            position: absolute;
            left: -8.333333333vw;
            right: -8.333333333vw;
            top: 0;

            @include sm() {
                width: 200%;
                margin-top: 0;
                position: static;
                margin-left: -93%;
            }

            &>svg {
                max-height: inherit;
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3:nth-child(2) {
            margin: 0 0 20px 0;

            @include sm() {
                margin: 0 0 14px 0;
            }
        }

        p {
            font-size: 20px;
            line-height: 1.4;

            @include sm() {
                font-size: 14px;
                line-height: 1.57;
            }
        }

        @include sm() {
            text-align: center;
        }
    }

    .moving-scooter {
        position: absolute;
        height: 480px;
        bottom: -150px;
        right: 0;
        margin: 0 auto;
        z-index: 2;
        transition: transform 1s ease, opacity .5s ease;
        opacity: 0;
        will-change: transform, opacity;

        @include sm() {
            display: none;
        }
    }

    &.ride-scooter {
        .moving-scooter {
            transform: translateX(-100%);
            transform: translateX(calc(-50vw + 50%));
            opacity: 1;
        }
    }
    .row-day-pass {
        gap: 92px;
        align-items: center;
        justify-content: flex-start;
        padding: 90px 0 60px 0;


        @include sm() {
            padding: 0 0 60px 0;
            gap: 40px;
        }


        .day-pass {
            @include sm() {
                text-align: center;
            }
        }

    }

    .row-first {
        width: 100%;
        justify-content: space-between;

        @include sm() {
            width: inherit;
            flex-direction: column-reverse;
            justify-content: inherit;
            .description {
                width: inherit;
            }
        }
    }
    .day-pass-image {
        position: relative;
        width: 590px;
        height: 400px;
        background: url('../../../assets/img/img-day-pass-base.png') center/contain no-repeat;
        max-width: 100%;

        @include lg() {
            width: 100%;
            height: 67.7%;
            min-height: 300px;
        }
    }
    .day-pass-image::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('../../../assets/img/img-day-pass-border.png') center/contain no-repeat;
    }
    .day-pass-hand {
        position: absolute;
        top: 0;
        left: 5%;
        right: 0;
        bottom: 0;
        background: url('../../../assets/img/img-day-pass-hand.png') center/contain no-repeat;
        animation: hand-shake 5s infinite;
        width: 90%;
    }

    @keyframes hand-shake {
        0% {
            transform: translateX(0px);
        }
        2.5% {
            transform: translateX(-5px);
        }
        5% {
            transform: translateX(5px);
        }
        7.5% {
            transform: translateX(-5px);
        }
        10% {
            transform: translateX(5px);
        }
        12.5% {
            transform: translateX(-5px);
        }
        15% {
            transform: translateX(5px);
        }
        17.5% {
            transform: translateX(-5px);
        }
        20% {
            transform: translateX(0px);
        }
    }
}

.ie,
.edge {
    .go-everywhere {
        .wrapper {
            padding-bottom: 416px;
        }
    }
}
