@mixin dot-wave {
    @keyframes dot-wave {
        from {
            opacity: 1;
            transform: scale(1);
        }
        to {
            opacity: 0;
            transform: scale(8);
        }
    }
}

@mixin stroke-line {
    @keyframes stroke-line {
        0% {
            opacity: 1;
            stroke-dashoffset: 200;
        }
        50% {
            opacity: 1;
            stroke-dashoffset: 0;
        }
        100% {
            opacity: 0;
            stroke-dashoffset: 0;
        }
    }
}

@mixin zoom-in {
    @keyframes zoom-in {
        from {
            transform: scale(1.5);
        }
        to {
            transform: scale(1);
        }
    }
}

@mixin fade-in {
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin fade-out {
    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
