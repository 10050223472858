@import "../../../scss/setup/variable.scss";
@import "../../../scss/mixins/media-query.scss";

.home-hero {
    background-color: $teal;
    background-color: var(--teal);
    color: $font-color;
    position: relative;
    overflow: hidden;
    padding: 0 0 50px 0;

    .wrapper {
        height: 100%;
    }

    .cover-image {
        margin: 0 auto;
        padding: 50px 0 0 0;
        height: 560px;
        max-width: 100%;
        transform: scale(1.5);
        transition: all .5s cubic-bezier(0, 0, .2, 1);

        &.transition-section {
            transform-origin: center top;
        }

        &.shrink {
            transform: scale(1);
        }

        @include portrait() {
            transform: scale(1.1) translateY(-80px);
            height: calc(100vh / 1.1666666667);
            padding: 0;
            width: 100vw;
            max-width: 80%;

            &.shrink {
                transform: scale(1) translateY(-80px);
            }

            @include md() {
                height: auto;
                margin-top: 120px;
                max-width: 60%;
            }

        }
    }

    .transition-section {
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0, 0, .2, 1);
        transition-duration: .4s;
        transform-origin: bottom;
    }

    .slide-up {
        transform: translateY(100px);
        opacity: 0;
    }

    .description-wrapper {
        margin: -30px auto 0;

        @include portrait() {
            margin: -90px auto 0 auto;
        }

        &.show {
            .slide-up {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    .caption {
        margin: 0 0 28px 0;
        font-weight: bold;
        text-align: center;
        transition-delay: .3s;

        @include sm() {
            font-size: 3.2rem;
            margin: 0 0 12px 0;
        }
    }

    .hero-description {
        padding: 0 14.23611111%;
        max-width: $screen-max-desktop;
        text-align: center;
        margin: 0 auto 60px auto;
        transition-delay: .45s;

        @include sm() {
            margin: 0 auto 18px auto;
            padding: 0 15px;
        }

        @include xs() {
            padding: 0;
        }

        .decription {
            line-height: 1.4;
            font-size: 22px;
            margin: 0 0 1rem 0;
            max-width: 894px;

            @include sm() {
                font-size: 16px;
            }

            &:last-child {
                margin: 0 auto;
            }

            .text-wrap {
                display: block;
            }
            .text-wrap-ex-sm {
                display: block;
                @include sm() {
                    display: inline;
                }
            }

            .text-wrap-sm {
                @include sm() {
                    display: block;
                }
            }
        }
    }

    .download {
        text-align: center;
        transition-delay: .45s;
        position: relative;
        z-index: 3;

        .btn-download {
            margin: 0 auto 12px auto;

            @include sm() {
                margin: 0 auto;
            }
        }

        .available-platform {
            font-size: 13px;

            @include sm() {
                display: none;
            }
        }
    }
}

.zh {
    .hero {
        .caption {
            font-size: 64px;

            @include sm() {
                font-size: 32px;
            }
        }
        .hero-description {
            .decription {
                line-height: 32px;

                @include sm() {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}
