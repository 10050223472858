@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";

.standard-container {
    margin: 0;
    width: 100vw;
    box-sizing: border-box;

    &.ghost-header {
        &::before {
            content: "";
            display: block;
            width: 100vw;
            height: $desktop-header-height;
            position: sticky;
            top: 0;
            opacity: .9;
            z-index: 1;

            @include sm() {
                height: $mobile-header-height;
            }
        }
    }

    .wrapper {
        padding-left: 8.333333333%;
        padding-right: 8.333333333%;
        width: 100%;
        max-width: $screen-max-desktop;
        margin: 0 auto;

        @include sm() {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
