@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/flex-center.scss";
@import "../../scss/mixins/media-query.scss";

$pic-desktop-width: 224px !default;

.newsroom {
    .news-list {
        width: 720px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
        list-style: none;

        @include sm() {
            width: 100%;
            padding: 0 24px;
        }

        .news-item {
            border-bottom: 1px #ededed solid;
            padding: 0 0 24px 0;
            margin: 0 0 32px 0;
        }

        .wrapper {
            @include flex-center();
            justify-content: space-between;
            text-align: left;
            width: 100%;

            @include sm() {
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column-reverse;
            }

            &.with-pic {
                .content {
                    width: calc(100% - #{$pic-desktop-width} - 32px);

                    @include sm() {
                        width: 100%;
                    }
                }
            }
        }

        .publish-time {
            &::after {
                content: "|";
                margin: 0 6px;
                font-size: 14px;
                vertical-align: top;
            }
        }

        h6 {
            color: #09ccd9;
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 8px 0;
        }

        h2 {
            color: #323237;
            font-size: 24px;
        }

        .news-picture {
            background-size: cover;
            height: 126px;
            width: 224px;

            @include sm() {
                margin: 0 0 16px 0;
                padding: 0;
                height: calc((100vw - 48px) * .5625);
                width: calc(100vw - 48px);
            }
        }
    }

    .request-notice {
        font-size: 16px;
        color: #7c8c8f;
        margin: 0 0 80px 0;
        text-align: center;

        a {
            color: #7c8c8f;
        }

        @include sm() {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
