@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/flex-center.scss";
@import "../../scss/mixins/media-query.scss";

.service {
    color: #173e6f;

    .hero {
        margin: 0;

        h1 {
            font-family: "TTNormsPro-Bold", $global-font-family;
            margin: 0 auto;
            width: 700px;
            max-width: 100%;
            span {
                @include sm() {
                    display: block;
                }
            }
        }
    }

    h2 {
        font-size: 60px;
        font-weight: 600;
        font-family: "TTNormsPro-Bold", $global-font-family;

        @include sm() {
            font-size: 32px;
            text-align: center;
        }
        span {
            @include sm() {
                display: block;
            }
        }
    }

    h3 {
        font-size: 36px;
        font-family: "TTNormsPro-Bold", $global-font-family;
        margin: 0 0 12px;

        @include sm() {
            font-size: 19px;
            text-align: center;
            margin: 4px 0 24px 0;
        }
    }

    ul {
        list-style: none;
        font-size: 16px;
        line-height: 24px;

        @include sm() {
            font-size: 16px;
        }

        li {
            display: flex;

            &::before {
                content: "●";
                color: $teal;
                display: inline-block;
                font-size: 12px;
                padding: 0 8px 0 4px;
                text-align: center;
            }
        }
    }

    .models .wrapper {
        padding-bottom: 268px;

        @at-root .en {
            .models .wrapper {
                padding-bottom: 303px;
            }
        }

        @include md() {
            padding-bottom: 315px;
        }
        @include sm() {
            padding-bottom: 518px;
        }

        .feature-wrap {
            @include flex-center();
            flex-direction: row;
            flex-wrap: wrap;
            @include lg() {
                flex-direction: column;
            }

            @media (min-width: $screen-lg) {
                width: 1123.03px; // for larger screen
            }
        }

        .feature.model-viva-img {
            margin: -36px 0 0 -113px;
            width: 595px;
            max-width: 100%;
            order: 2;

            @include lg() {
                margin: -37px 0 11px 0;
                order: unset;
            }
        }

        .feature.model-viva {
            min-width: 522px;

            @include lg() {
                margin: 0 0 112px 0;
                min-width: unset;
            }

            .wireless-charging {
                padding: 24px 0 0 0;
            }

            .note {
                margin-top: 4px;
                font-size: 12px;
                opacity: .6;
                @include sm() {
                    margin-top: 8px;
                }
            }
        }

        .model-2,
        .model-2-img {
            margin: -31px 0 0 0;

            @include lg() {
                margin: 0;
            }
        }

        .feature.model-2 {
            padding: 0 0 0 79px;

            @include lg() {
                padding: 0;
            }

            h2 {
                font-size: 44px;

                @include sm() {
                    font-size: 32px;
                }
            }

            h3 {
                font-size: 24px;

                @include sm() {
                    font-size: 19px;
                }
                &.service-models {
                    max-width: 370px;
                    line-height: 1.25;

                    @include sm() {
                        max-width: auto;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            li {
                max-width: 520px;
            }
        }

        .feature.model-2-img {
            width: 306px;
            margin-left: -83px;

            @include sm() {
                margin-left: 0;
                width: 230px;
            }
        }

        .feature {
            display: flex;
            justify-content: center;
            flex-direction: column;

            h2, h3, ul, .wireless-charging {
                opacity: 0;
            }

            &.active {
                h2 {
                    animation: slide-up .5s ease forwards;
                }

                h3 {
                    animation: slide-up .5s .15s ease forwards;
                }

                ul, .wireless-charging {
                    animation: fade-in .5s .5s ease forwards;
                }
            }
        }

        .wireless-charging {
            display: flex;
            flex-direction: row;
            align-items: center;

            & > .icon {
                margin: 0 19px 0 0;
                width: 128px;
            }
        }

        .indicator {
            display: flex;
            flex-direction: column;

            & > .icon {
                margin: 0 0 4px 0;
                width: 32px;
            }
        }
    }

    .most-fun {
        background: $gogoro-blue;
        position: relative;
        padding: 346px 0 120px 0;

        @at-root .en .service {
            .most-fun {
                padding-top: 384px;
            }
        }

        @include lg() {
            padding-top: 286px;
        }
        @include sm() {
            padding: 415px 0 64px 0;
        }
        @media (max-width: 414px) {
            padding-top: 415px;
            @at-root .en .service {
                .most-fun {
                    padding-top: 442px;
                }
            }
        }
        @media (max-width: 375px) {
            padding-top: 440px;
            @at-root .en .service {
                .most-fun {
                    padding-top: 460px;
                }
            }

        }

        .most-fun-container {
            color: #fff;
            width: 800px;
            max-width: 100%;
            margin: 0 auto;
            padding: 0;
            text-align: center;

            h2 {
                margin: 0 0 21px 0;
                font-size: 44px;

                @include sm() {
                    margin: 0 0 32px 0;
                    font-size: 30px;
                }
            }

            p {
                margin: 0 0 60px 0;

                @include sm() {
                    margin: 0 0 40px 0;
                }
            }

            .pricing-motion {
                margin: 0 auto 60px;
                padding-top: 160px;

                @include sm() {
                    margin: 0 -24px 40px;
                    padding-top: 120px;
                }

                &:empty {
                    background: #ccc;
                    height: 200px;
                }
            }

            .pause-fee-notice {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 24px;
                text-align: center;

                @include sm() {
                    margin-bottom: 16px;
                }
                @at-root .en .service .most-fun .most-fun-container {
                    .pause-fee-notice {
                        font-family: "TTNormsPro-Bold", $global-font-family;
                        font-weight: 700;
                        line-height: 23.96px;
                    }
                }
            }

            .notice {
                font-size: 12px;
                line-height: 14.38px;
                margin: 0;
            }
        }
        .pricing-anchor {
            height: 1px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            @include sm() {
                top: 124px;
            }
        }
    }

    .go-badges {
        max-width: 750px;
        padding: 54px 0;
        text-align: center;
        display: flex;
        align-items: flex-start;
        color: #173E6F;
        border-radius: 20px;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        transform: translateY(-50%);

        @include sm() {
            margin: 0 24px;
        }
        @include md() {
            transform: translateY(-56.4%);
            @at-root .en .service {
                .go-badges {
                    transform: translateY(-54.4%);
                }
            }
        }
        @media (max-width: 375px) {
            transform: translateY(-54.7%);
            @at-root .en .service {
                .go-badges {
                    transform: translateY(-53.4%);
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: calc(50% - 56px);
            right: calc(50% - 56px);
            bottom: 50%;
            opacity: 0;
            background-color: $teal;
            border-radius: 20px;
            box-shadow: 0 15px 30px rgba(0,0,0,.1);


        }

        &.active {
            &::before {
                animation: box-expand .75s ease-in-out forwards;
            }
            .feature-wrap {
                animation: fade-in .25s .55s ease-in forwards;
            }

            .icon {
                animation: fade-in .25s .75s ease-in forwards;
            }

            .title {
                animation: fade-in .25s .85s ease-in forwards;
            }

            .description {
                animation: fade-in .25s .95s ease-in forwards;
            }
        }

        @include sm() {
            flex-direction: column;
            padding: 52px 24px;
            text-align: center;
            font-size: 16px;
        }

        .feature-wrap {
            display: flex;
            flex-direction: column;
            padding: 4px 54px 4px 54px;
            position: relative;
            width: 50%;
            opacity: 0;

            &:first-child {
                border-right: 1px solid  rgba($blue-dark, .2);
            }

            @include sm() {
                flex-direction: column;
                padding: 0;
                width: 100%;

                &:first-child {
                    border-bottom: 1px solid  rgba($blue-dark, .2);
                    border-right: none;
                    margin-bottom: 52px;
                    padding-bottom: 52px;
                }
            }



            .content {
                width: 266px;
                margin: 0 auto;

                @include sm() {
                    width: 288px;
                }
                @include md() {
                    width: 100%;
                }
            }
            .icon {
                display: flex;
                margin: 0 auto 32px;
                height: 100px;
                width: 100px;
                opacity: 0;


                @include sm() {
                    margin: 0 auto 40px;
                }
            }
        }

        .title {
            font-family: "TTNormsPro-Bold", $global-font-family;
            font-size: 44px;
            margin: 0 0 16px 0;
            opacity: 0;

            @include sm() {
                margin-bottom: 12px;
            }
        }

        .description {
            line-height: 1.44;
            opacity: 0;
            font-family: "TTNormsPro-Regular", $global-font-family;
            font-size: 18px;
            @include xs() {
                font-size: 16px;
                width: 101%;
            }

            .magic-r {
                @include xs() {
                    margin-right: -1px;
                }
            }
        }
        .notes {
            color: rgba($blue-dark, .5);
            font-size: 12px;
            line-height: 2.17;
            text-align: center;
            margin-top: 8px;
            font-family: "TTNormsPro-Regular", $global-font-family;

        }

    }

    .find-us {
        background: $green;
        padding: 80px 0;
        text-align: center;

        @include sm() {
            padding: 64px 0 122px 0;
        }

        h2 {
            margin: 0 0 20px 0;
            font-size: 44px;
            font-weight: bold;

            @include sm() {
                margin: 0 0 40px 0;
                font-size: 30px;
            }
        }

        .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;

            @include sm() {
                font-size: 20px;
            }

        }
        .description {
            font-size: 20px;
            line-height: 1.4;
            max-width: 760px;
            width: 760px;
            max-width: 100%;
            margin: 0 auto;

            @include sm() {
                font-size: 16px;
                line-height: 1.5;
            }

            span {
                display: block;

                @include sm() {
                    display: inline;
                }
            }
        }

        .service-zones {

            &>section {
                margin: 80px 0 80px 0;

                &:first-child {
                    @include sm() {
                        margin-top: 0;
                    }
                }
                &:last-child {
                    margin-top: 100px;
                    margin-bottom: 0;
                }
            }

        }

        .find-us-city {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .zones {
                display: flex;
                flex-direction: row;
                margin: 40px auto 16px;
                flex-wrap: wrap;
                width: 772px;
                height: fit-content;
                justify-content: space-around;

                @include md() {
                    width: 652px;
                    height: fit-content;
                }


                @media (max-width: 769px) {
                    width: 100%;
                    flex-wrap: inherit;
                    flex-direction: column;
                    margin-bottom: 32px;
                    height: auto;
                }
            }

            .zone {
                font-size: 36px;
                font-weight: bold;
                text-align: center;
                color: #173e6f;
                height: 200px;
                margin-bottom: 24px;

                @include md() {
                    height: auto;
                    margin-bottom: 12px;
                }

                @include sm() {
                    padding-bottom: 12px;
                    box-sizing: content-box;
                    height: 181px;
                    margin-bottom: 0;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                .location {
                    width: 362px;
                    height: 200px;
                    border-radius: 20px;
                    box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.05);
                    background-color: $bright-aqua;

                    @include md() {
                        width: 308px;
                        height: 170px;
                    }

                    @media (max-width: 769px) {
                        margin: 0 auto ;
                        width: 327px;
                        height: 181px;
                    }


                }
                &:last-child {
                    margin-right: 0 ;

                    @include lg() {
                        margin-bottom: 0;
                    }
                }

            }
        }

        .find-us-dots {

            .area {
                width: 100%;
                height: 100%;
                max-width: 748px;
                max-height: 400px;
                margin: 40px auto 48px;
                box-shadow: 0 15px 30px rgba(0,0,0,.1);
                border-radius: 20px;

                @include sm() {
                    max-width: 327px;
                    max-height: 320px;
                }
                @include xs() {
                    margin-bottom: 32px;
                }
            }
            .dots-mobile {
                display: none;
                @include sm() {
                    display: block;
                }
            }
            .dots-desktop {
                width: 100%;
                height: auto;
                display: inherit;
                @include sm() {
                    display: none;
                }
            }
        }

        .notice {
            opacity: .5;
            font-size: 12px;
            line-height: 18px;

            @include sm() {
                font-size: 12px;
                line-height: 16px;
                width: 240px;
                margin: 0 auto;
            }
        }
    }
}

.zh {
    .service .hero h1 {
        width: 100%;
    }
}
.en {
    .service .find-us .find-us-city .zone {
        font-size: 30px;
        font-family: "TTNormsPro-Bold", $global-font-family;
        @include sm() {
            font-size: 20px;
        }
    }
}

@keyframes box-expand {
    0% {
        opacity: 0;
    }

    50% {
        top: 0;
        left: calc(50% - 56px);
        right: calc(50% - 56px);
        bottom: 0;
        opacity: 1;
    }

    100% {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
