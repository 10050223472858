@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";

.app {
    min-height: 100%;

    .btn {
        border: none;
        cursor: pointer;
        white-space: nowrap;

        &[disabled] {
            pointer-events: none;
        }
    }

    .btn.btn-primary {
        margin: 0 1px;
        color: $font-color;
        display: inline-block;
        font-family: "TTNormsPro-Bold", $global-font-family;
        font-size: 16px;
        line-height: 1;
        border-radius: 24px;
        padding: 16px 32px;
        background-color: var(--yellow);
        background-color: #f5fb6b;
        transition: filter .3s ease;
        outline: none;

        &:hover {
            filter: opacity(.7);
        }
    }

    .btn.btn-dark {
        margin: 0 1px;
        color: white;
        display: inline-block;
        font-family: "TTNormsPro-Bold", $global-font-family;
        font-size: 18px;
        line-height: 1;
        border-radius: 24px;
        padding: 12px 32px;
        background-color: #173e6f;
        transition: filter .3s ease;
        outline: none;

        &:hover {
            filter: opacity(.7);
        }
        @include xs() {
            font-size: 16px;
        }
    }
}

.zh {
    .btn.btn-primary {
        font-weight: bold;
    }
    .btn.btn-dark {
        font-weight: bold;
    }
}

.text-wrap {
    display: block;
}

.text-wrap-ex-sm {
    display: block;
    @include sm() {
        display: inline;
    }
}
.text-inline-ex-sm {
    display: inline-block;
    @include sm() {
        display: inline;
    }
}

.text-wrap-sm {
    @include sm() {
        display: block;
    }
}

.text-wrap-xs {
    @include xs() {
        display: block;
    }
}
