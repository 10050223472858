// colors
$black:  #000 !default;
$gogoro-blue: #0074ff !default;
$blue-dark: #173e6f;
$dark: #3a3f52 !default;
$green: #01ffda !default;
$gray: #857f8c !default;
$light-gray: #f1f0f2 !default;
$other-gray: #f0eff1 !default;
$smoke-gray: #f8f8f8 !default;
$pink: #f296bb !default;
$teal: #06f3f9 !default;
$white: #fff !default;
$yellow: #f5fb6b !default;
$red: #e02020 !default;
$bright-aqua: #06f3f9 !default;

// font
$font-color: #173e6f !default;
$global-font-family: sans-serif, PingFangTC-Regular, Microsoft JhengHei !default;
$global-font-family-bold: sans-serif, PingFangTC-Semibold, Microsoft JhengHei !default;

// size + padding
$desktop-header-height: 120px !default;
$mobile-header-height: 80px !default;
$desktop-side-padding: 90px !default;
$mobile-side-padding: 24px !default;
$screen-max-desktop: 1440px !default;
