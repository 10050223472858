@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/flex-center.scss";
@import "../../scss/mixins/media-query.scss";

.not-found {
    background: $teal;

    // .header {
    //     background: $teal;
    //     position: sticky;
    // }

    .container {
        height: 50vh;
        @include flex-center();
    }
}
