@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/flex-center.scss";
@import "../../scss/mixins/media-query.scss";


.hero {
    background-color: $teal;
    background-color: var(--teal);
    margin: 0 0 80px 0;
    padding: 120px 0 168px 0;

    @include sm() {
        padding: 84px 0 103px 0;
    }

    &.blue-dark {
        background-color: $blue-dark;
        background-color: var(--blue-dark);
        h1 {
            color: #fff;
        }
    }

    h1 {
        color: #173e6f;
        font-size: 60px;
        font-family: "TTNormsPro-Bold", $global-font-family;
        font-weight: bold;
        text-align: center;
        padding: 0 24px;

        @include sm() {
            font-size: 40px;

        }
    }

    .description {
        max-width: 900px;
        margin: 0 auto;
        color: #fff;
        font-size: 22px;
        text-align: center;

        @include md() {
            padding: 0 10.625%;
        }
        @include sm() {
            padding: 0 24px;
            font-size: 16px;
        }
        p {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }
}
