@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";
@import "../../scss/mixins/animation.scss";

@include fade-in();

.partnerships {
    .header {
        transition: background-color 0.5s ease-in;
    }
    .transparent-header {
        background-color: transparent;
    }

    .hero {
        padding-top: 200px;
        padding-bottom: 120px;
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
        margin-top: -120px;

        .logo {
            width: 324px;
            height: 123px;
            margin: 0 auto 80px;

            @include sm() {
                width: 247px;
                height: 94px;
                margin: 0 auto 60px;
            }
        }
        .para {
            font-size: 22px;

            @include xs() {
                font-size: 16px;
            }
        }
        .context {
            max-width: 900px;
            margin: 0 auto;
            color: white;
            font-size: 22px;
            text-align: center;
            opacity: 0;
            animation: fade-in 3s 2s ease-out forwards;
        }
    }
    .oversea-gojek {
        background-color: $green;
        color: $blue-dark;
        text-align: center;
        padding: 80px 0;
        overflow: hidden;

        .oversea-gojek-img {
            display: inline-block;
            margin: 0 auto 24px;
            width: 482px;

            @include sm() {
                margin: 0 -140px 40px;
            }
        }

        h2 {
            font-family: "TTNormsPro-Bold", $global-font-family;
            font-size: 38px;
            text-align: center;
            font-weight: 600;
            margin-bottom: 24px;

            @include sm() {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }
        .oversea-gojek-content {
            max-width: 640px;
            margin: 0 auto;
        }

        .oversea-gojek-intro {
            font-size: 20px;
            line-height: normal;
            margin-bottom: 24px;

            @include xs() {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
        .oversea-gojek-quote {
            font-family: "TTNormsPro-Bold", $global-font-family-bold;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: normal;

            @include xs() {
                font-size: 13px;
            }
        }
    }

    .features {
        background-color: #fff;
        padding: 120px 0;

        @include xs() {
            padding: 80px 0;
        }

        h2 {
            font-family: "TTNormsPro-Medium", $global-font-family;
            font-weight: 500;
            font-size: 64px;
            line-height: 1.22;
            color: $blue-dark;
            text-align: center;
            margin-bottom: 112px;

            @include xs() {
                font-size: 36px;
                margin-bottom: 40px;
            }
        }
    }
    .feature-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-bottom: 120px;
        flex-direction: row;
        max-width: 1120px;

        &:nth-of-type(odd) {
            flex-direction: row-reverse;
            @include lg() {
                flex-direction: column;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
        @include lg() {
            margin-bottom: 80px;
            flex-direction: column;
        }


        .col {
            display: flex;
            flex-direction: column;
        }

        .image {
            align-self: center;
            width: 480px;
            height: auto;
            border-radius: 20px;

            @include lg() {
                width: 100%;
                height: auto;
                margin-bottom: 40px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .description {
            align-self: center;
            max-width: 580px;

            @include lg() {
                text-align: center;
                align-items: center;
            }

            .caption {
                color: $blue-dark;
                font-size: 40px;
                font-weight: 500;

                @include xs() {
                    font-size: 28px;
                }
            }
            .content {
                margin: 24px 0;
                font-size: 20px;
                line-height: 28px;
                @include xs() {
                    margin: 20px 0;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .btn-cta {
                width: 181px;
                padding: 12px 24px;
                font-size: 14px;
                line-height: 18px;
            }

        }

    }

    .feature-partners {
        background-color: $smoke-gray;
        padding: 120px 0;

        @include xs() {
            padding: 80px 0;

        }

        h2 {
            color: $blue-dark;
            font-size: 44px;
            line-height: 1.2;
            text-align: center;
            font-weight: bold;
            font-family: "TTNormsPro-Bold", $global-font-family;
            margin-bottom: 80px;

            @include xs() {
                font-size: 30px;
                line-height: 1.18;
                margin-bottom: 48px;
            }
        }

        .partners-list {
            list-style: none;
            justify-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 850px;
            margin: -10px auto;

            @include lg() {
                width: 100%;
                margin-bottom: -20px;
                justify-content: center;
                padding: 0 20px;
            }
            @media (max-width: $screen-sm - 1) {
                margin-bottom: -24px;
                justify-content: space-between;
                padding: 0;
                width: calc(100% + 24px);
                margin-left: -12px;
                margin-right: -12px;
            }

            &.is-expanded {
                @media (min-width: 1200px) {
                    min-width: 1020px;
                }

            }

            .partners-item {

                width: 150px;
                height: 150px;
                border-radius: 15px;
                margin: 10px;

                @include lg() {
                    width: calc(25% - 2 * 10px);
                    height: calc(25% - 2 * 10px);
                    margin: 0 10px 2*10px;
                }

                @media (max-width: $screen-sm - 1) {
                    width: calc(50vw - 12px - 24px);
                    height: calc(50vw - 12px - 24px);
                    margin: 0 auto 24px auto;
                }

                &.is-break {
                    flex-basis: 100%;
                    height: 0;
                    margin: 0;
                    @include lg() {
                        display: none;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
