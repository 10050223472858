@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";
@import "../../scss/mixins/flex-center.scss";

.view.download {
    background: $teal;
    background: var(--teal);

    .container {
        text-align: center;
        padding: 40px 0 142px 0;
    }

    .header {
        background: $teal;
        background: var(--teal);
        position: sticky;
    }

    .caption {
        color: #fff;
        margin: 0 0 20px 0;
    }

    .subtitle {
        margin: 0 0 80px 0;
        font-size: 20px;
    }

    .dynamic-link {
        margin: 0 0 30px 0;
    }

    .description {
        font-size: 14px;
    }
}
