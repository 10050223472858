@import "../../../scss/setup/variable.scss";
@import "../../../scss/mixins/media-query.scss";
@import "../../../scss/mixins/flex-center.scss";

.service-switch {
    height: 48px;
    width: 353px;
    border-radius: 24px;
    background-color: #4096ff;
    margin: 30px auto;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include sm() {
        width: 327px;
    }
    @media (max-width: 320px) {
        width: auto;
    }

    .item {
        font-family: "TTNormsPro-Bold", $global-font-family;
        font-size: 16px;
        width: 50%;
        line-height: 48px;
        text-align: center;
        height: 48px;

        &.on {
            background-color: var(--white);
            color: var(--gogoro-blue);
            height: 36px;
            line-height: 36px;
            border-radius: 20px;
        }
    }
}
