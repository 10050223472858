@import "./scss/setup/variable.scss";
@import "./scss/mixins/media-query.scss";

// root variables
:root {
    --black: #{$black};
    --gogoro-blue: #{$gogoro-blue};
    --blue-dark: #{$blue-dark};
    --dark: #{$dark};
    --green: #{$green};
    --gray: #{$gray};
    --light-gray: #{$light-gray};
    --other-gray: #{$other-gray};
    --pink: #{$pink};
    --teal: #{$teal};
    --white: #{$white};
    --yellow: #{$yellow};
    --red: #{$red};
}

html {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

html, body, #root {
    font-family: "TTNormsPro-Regular", $global-font-family;
    width: 100vw;
    min-height: 100%;
}

body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

}

body {
    background-color: #fafafa;
    font-size: 16px;
    color: $dark;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    font-weight: normal;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    * {
        box-sizing: border-box;
    }

    @include sm() {
        font-size: 14px;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
}

h1 {
    font-family: "TTNormsPro-ExtraBold", $global-font-family;
    font-size: 5rem;

    @include sm() {
        font-size: 52px;
    }
}

h2 {
    font-family: "TTNormsPro-Medium", $global-font-family;
    font-size: 4rem;

    @include sm() {
        font-size: 36px;
    }
}

h3 {
    font-family: "TTNormsPro-Medium", $global-font-family;
    font-size: 2.75rem;

    @include sm() {
        font-size: 28px;
    }
}

h5 {
    font-size: 1.25rem;

    @include sm() {
        font-size: 16px;
    }
}

h6 {
    font-size: 1rem;
}

a {
    background: transparent;
    border: 0;
    cursor: pointer;
    color: $white;
    color: var(--white);
    font-size: 1.125rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    outline: 0;

    &:hover {
        color: var(--yellow);
        color: #f5fb6a;
    }
}

ul {
    padding: 0;
    margin: 0;
}

p {
    margin: 0;
}

.text-center {
    text-align: center;
}

@font-face {
    font-family: "TTNormsPro-Bold";
    src: url("./assets/fonts/TT-Norms-Pro-Bold.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "TTNormsPro-ExtraBold";
    src: url("./assets/fonts/TT-Norms-Pro-ExtraBold.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "TTNormsPro-Medium";
    src: url("./assets/fonts/TT-Norms-Pro-Medium.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "TTNormsPro-Regular";
    src: url("./assets/fonts/TT-Norms-Pro-Regular.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}
