@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/flex-center.scss";
@import "../../scss/mixins/media-query.scss";

$spacing-s: 20px;
$spacing-m: 40px;
$spacing-l: 80px;
$spacing-mobile-l: 60px;

.ma-program {
    // variables use in ma-program
    --spacing-s: #{$spacing-s};
    --spacing-m: #{$spacing-m};
    --spacing-l: #{$spacing-l};
    --spacing-dy-l: #{$spacing-l};

    @include sm() {
        --spacing-dy-l: #{$spacing-mobile-l};
    }
    font-size: 16px;
    min-height: 100%;
    color: var(--blue-dark);
    background: $white;
    background: var(--white);

    @include sm() {
        font-size: 14px;
    }
    .wrapper {
        padding: var(--spacing-dy-l) 0;
        width: 720px;
        margin: 0 auto;

        @include sm() {
            width: 100%;
            padding: var(--spacing-dy-l) 24px;
        }
        &.no-pb {
            padding-bottom: 0;
        }
        &.no-pt {
            padding-top: 0;
        }
    }
    .ma-header {
        text-align: center;
        min-height: 575px;// Neco
        background-image: url(../../assets/img/ma-hero.svg);
        background-color: var(--teal);
        background-size: 1005px 154px;
        background-repeat: no-repeat;
        background-position: center calc(100% - var(--spacing-l));
        padding: 0 24px 0 24px;
        text-align: center;
        @include sm {
            min-height: 499px;// Neco
            background-size: cover;
            background-size: 744px 114px;
        }
        .title {
            font-size: 60px;
            font-weight: normal;
            font-family: "TTNormsPro-Medium", $global-font-family;
            color: var(--blue-dark);
            padding-bottom: 8px;

            @include sm {
                font-size: 32px;
            }
        }

        .sub-title {
            font-size: 14px;
            line-height: 1.2;
        }

        .logo {
            display: inline-block;
            width: 180px;
            height: 87px;
            margin: 0 auto 0 auto;
            padding-top: var(--spacing-l);
            padding-bottom: var(--spacing-m);
            box-sizing: content-box;
            @include sm {
                width: 140px;
                height: 68px;
            }
        }

    }

    .title{
        &-h2 {
            text-align: left;
            font-size: 60px;
            color: var(--blue-dark);
            margin-bottom: var(--spacing-m);
            font-family: "TTNormsPro-bold", $global-font-family;
            @include sm() {
                font-size: 40px;
            }
            &.s-mb {
                margin-bottom: var(--spacing-s);
            }
            &>span {
                @include sm() {
                    display: block;
                }
            }
        }

        &-h3 {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.4;
            color: var(--blue-dark);
            font-family: "TTNormsPro-Bold", $global-font-family;
            margin-bottom: var(--spacing-s);

            &>span {
                @include sm() {
                    display: block;
                }
            }
        }
        &-white {
            color: var(--white);
        }
    }
    .list {
        list-style-type: none;
        list-style-position: inside;

        & > li {
            margin-bottom: var(--spacing-s);
            padding-left: 24px;
            line-height: 1.5;
            @include sm() {
                line-height: 1.43;
            }
            &::before {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: var(--blue-dark);
                margin-left: -24px;
                margin-right: 16px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .para {
        margin-bottom: var(--spacing-s);


        &:last-child {
            margin-bottom: 0;
        }
        &-l {
            font-size: 20px;
            line-height: 28px;
            @include sm() {
                font-size: 16px;
            }
            .email {
                font-size: 20px;
                @include sm() {
                    font-size: 16px;
                }
            }
        }
    }

    .bg-green {
        background-color: var(--green);
    }
    .bg-blue {
        background-color: var(--gogoro-blue);
    }
    .bg-teal {
        background-color: var(--teal);
    }
    .bg-dark {
        background-color: var(--blue-dark);
    }

    .section {
        margin-bottom: var(--spacing-m);

        &.no-mb {
            margin-bottom: 0;
        }
    }

    .timeline {

        &-item {
            display: flex;

            &:first-child {
                .timeline-dot {
                    border-radius: 20px 20px 0 0;
                }
            }
            &:last-child {
                padding-bottom: 0;
                .timeline-dot {
                    border-radius: 0 0 20px 20px;
                    height: 40px;
                }
            }
        }
        &-dot {
            background-color: #f3f3f3;
            padding: 8px;
            padding-bottom: var(--spacing-s);
            margin-right: 16px;
            &::before {
                content: '';
                display: inline-block;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                background-color: var(--gogoro-blue);
            }
        }
        &-content {
            display: flex;
            align-items: flex-start;
            margin-bottom: var(--spacing-s);
            width: 100%;
            flex-wrap: wrap;
        }
        &-date {
            margin-bottom: 4px;
            flex: 0 0 100%;
        }
        &-title {
            font-weight: bold;
            font-family: "TTNormsPro-Bold", $global-font-family;
            font-size: 20px;
            flex: 0 0 100%;
            @include sm() {
                font-size: 18px;
            }
        }
        &-link {
            margin-left: 8px;
            font-family: "TTNormsPro-Regular", $global-font-family;
            @include xs() {
                display: block;
                text-align: inherit;
                margin-left: 0;
            }
        }
    }

    .orders {
        &-item {
            display: flex;
            margin-bottom: var(--spacing-s);
        }

        &-number {
            font-family: "TTNormsPro-Bold", $global-font-family;
            font-size: 48px;
            font-weight: bold;
            color: var(--gogoro-blue);
            padding: 0 30px 0 0px;
            flex: 0 0 30px;
            text-align: center;
            box-sizing: content-box;
            line-height: 48px;
        }

        &-content {
            display: flex;
            align-self: center;
            flex-direction: column;
            width: calc(100% - 60px);
            .list {
                margin-top: 8px;
                &>li {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .quote {
        margin-bottom: var(--spacing-m);
        color: var(--white);
        font-size: 28px;

        &:last-child {
            margin-bottom: 0;
        }
        &-para {
            line-height: 1.2;
        }
        &-said {
            font-size: 16px;
            font-style: normal;
            font-family: "TTNormsPro-Bold", $global-font-family;
            margin-top: var(--spacing-s);
            display: block;
            &::before {
                content: '-';
                margin-right: 4px;
            }
        }
    }
    .ma-footer {
        text-align: center;
        padding: 120px 0 var(--spacing-l) 0;
        max-width: 900px;
        margin: 0 auto;
        @include sm() {
            padding-right: 24px;
            padding-left: 24px;
            padding-top: var(--spacing-l);
        }
    }
    .gogoro-logo {
        display: inline-block;
        margin: 0 auto;
        margin-bottom: var(--spacing-dy-l);
        object-fit: none;
        width: 108px;
        height: 28px;
    }
    .copyright {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
    }
    .text-block {
        display: block;
    }
    .email {
        font-size: 16px;
        @include sm() {
            font-size: 14px;
        }
    }

    p {
        line-height: 1.5;
        @include sm() {
            line-height: 1.43;
        }
    }
    img[src*='.svg'] {
        min-width: 100%;
    }

    a {
        color: inherit;
        text-decoration: underline;
        font-size: 16px;
        &:hover {
            color: inherit;
        }
    }
}
