@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";
@import "../../scss/mixins/flex-center.scss";

.footer {
    background: #173e6f;
    font-family: "TTNormsPro-Medium", $global-font-family;

    @include sm() {
        padding: 0 24px;
    }

    &.ghost-header {
        &::before {
            background: #173e6f;

            @include sm() {
                width: 100%;
            }
        }
    }

    .wrapper {
        @include flex-center();
        flex-direction: column;
        color: var(--white);
        color: #fff;
        padding: 0 0 80px 0;

        @include sm() {
            padding: 20px 0 3rem;
        }

        .coming-soon {
            text-align: center;
            margin-bottom: 80px;
            font-size: 0.875rem;
            line-height: 18px;
            opacity: .3;
        }

        .icon {
            width: 149px;
            display: block;

            &:first-child {
                margin: 0 16px 0 0;
            }

            @include xs() {
                width: calc(50% - 12px);
            }
        }

        .downloads {
            @include flex-center();

            margin-bottom: 60px;

            @include xs() {
                justify-content: space-around;
                width: 100%;
            }
        }

        .contact {
            margin-bottom: 60px;

            a {
                display: block;
                margin: 0 0 12px 0;
            }
        }

        .social-link {
            color: var(--white);
            color: #fff;
            display: block;
            text-align: center;
            font-size: 1.125rem;
            font-weight: normal;
            margin-bottom: 12px;

            &:hover {
                color: var(--yellow);
                color: #f5fb6a;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .language {
            font-size: 13px;
            margin-bottom: 60px;
            color: #fff;

            .lang {
                border: 0;
                background: transparent;
                color: $white;
                color: var(--white);
                font-size: 1.125rem;
                font-weight: normal;
                cursor: pointer;
                outline: 0;

                &:hover {
                    color: var(--yellow);
                    color: #f5fb6a;
                }
            }

            span {
                padding: 0 1rem;
            }
        }

        .icons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 60px;

            .icon {
                margin: 0 24px 0 0;
                opacity: 1;
                height: 32px;
                width: 32px;

                img {
                    height: 100%;
                    width: 100%;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }

        .gogoro-logo {
            margin-bottom: 40px;
        }

        .copyright,
        .legal {
            color: rgba(255, 255, 255, .3);
            font-size: 14px;
            line-height: 18px;
            margin: 0;

            a {
                color: inherit;
                font-size: inherit;
                font-weight: normal;
            }
        }

        .legal {
            margin: 0 0 1rem 0;
            text-align: center;

            .link {
                display: inline-block;
                padding: 0 .5rem;
                line-height: 14px;
            }
        }

        .copyright {
            white-space: pre-wrap;
            max-width: 62.5%;
            text-align: center;
            font-family: "TTNormsPro-Medium", $global-font-family;

            @include sm() {
                max-width: 100%;
            }
        }
    }
}
