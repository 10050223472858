@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";
@import "../../scss/mixins/flex-center.scss";


.promotion {
    background: $teal;
    background: var(--teal);
    text-align: center;
    min-height: 440px;
    transition: background .5s ease;
    will-change: background;
    @include flex-center();
    flex-direction: column;
    position: relative;

    @include sm() {
        min-height: 320px;
    }

    &::before {
        background: $teal;
        background: var(--teal);
        transition: background .5s ease;
        will-change: background;
    }

    &.share-and-earn {
        background: $gogoro-blue;
        background: var(--gogoro-blue);

        &::before {
            background: $gogoro-blue;
            background: var(--gogoro-blue);
        }

        .caption {
            color: $teal;
            color: var(--teal);
        }

        .subtitle {
            color: rgba(255, 255, 255, .6);
        }
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 60px 24px;

    }

    .caption {
        color: #fff;
        font-family: "TTNormsPro-Medium", $global-font-family;
        font-weight: 500;
        line-height: 1.17;

        @include sm() {
            font-size: 36px;
        }
    }

    .subtitle {
        color: rgba(0, 0, 0, .3);
        max-width: 60%;
        margin: 30px auto 0;

        @include sm() {
            font-size: 14px;
        }
    }
}
