@import "../../scss/mixins/media-query.scss";

.privacy {
    display: flex;
    position: fixed;
    left: 24px;
    right: 24px;
    bottom: 24px;
    flex: 1;
    background: white;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    box-shadow: 0 0 20px 0 rgba(#000, .1);

    @include sm() {
        padding: 16px;
        left: 8px;
        right: 8px;
        bottom: 32px;
    }

    &.hide {
        display: none;
    }
    .privacy-container {
        max-width: 895px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        @include sm() {
            flex-direction: column;
        }
    }

    .privacy-content {
        width: 100%;
        margin: 0;
        color: #173e6f;
        margin-right: 40px;
        font-size: 16px;

        @include xs() {
            font-size: 13px;
        }
        @include sm() {
            margin-right: 0;
            text-align: center;
            margin-bottom: 16px;
        }

        @at-root {
            .zh {
                .privacy .privacy-content {
                    font-size: 14px;
                    @include xs() {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .privacy-link {
        font-size: 16px;
        color: inherit;
        line-height: normal;
        text-decoration: underline;
        display: inline-block;

        @include xs() {
            font-size: 13px;
        }

        @at-root {
            .zh {
                .privacy .privacy-link {
                    font-size: 14px;
                    @include xs() {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
