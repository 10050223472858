@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";

.header {
    height: $desktop-header-height;
    background-color: transparent;
    position: fixed;
    top: 0;
    transition: opacity .3s ease-out;
    opacity: 0;
    z-index: 9999;
    width: 100vw;
    overflow: hidden;

    &.sticky {
        background: $teal;
        position: sticky;
    }

    &.dark {
        .nav .link {
            color: #173e6f;

            &:hover {
                color: $yellow;
            }
        }
    }

    &.blue-dark {
        background-color: $blue-dark;
        .nav {
            .link {
                color: #fff;
            }
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    .nav {
        .link {
            margin: 0 40px 0 0;
            font-size: 16px;
            color: #fff;
            transition: color .3s ease-out;
            font-family: "TTNormsPro-bold", $global-font-family;
            &:hover {
                color: $yellow;
            }

            @include md() {
                display: none;
            }
            @at-root {
                .zh {
                    .nav {
                        .link {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    @include sm() {
        height: $mobile-header-height;
    }

    &.show {
        opacity: 1;
    }

    .logo-link {
        height: 78px;

        @include sm() {
            height: 46px;
        }
    }

    img {
        height: 100%;
    }
}
