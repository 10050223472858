@import "../../scss/mixins/media-query.scss";

.btn.btn-primary.btn-download {
    width: 177px;

    &:hover {
        filter: opacity(1);
    }
    @include sm() {
        width: 147px;
        padding: 12px 24px;
        font-size: 14px;
        line-height: 18px;
    }
}
