@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";
@import "../../scss/mixins/flex-center.scss";


.home {
    height: 100%;

    .present-scooter {
        background: linear-gradient(to bottom, #{$teal} 0%, #{$teal} 80%, #{$gogoro-blue} 80%, #{$gogoro-blue} 100%);
        position: relative;
        z-index: 2;

        &.trim {
            overflow: visible;
        }

        &::before {
            background: $teal;
            background: var(--teal);
        }

        &.show-static-bg {
            background-image: url(../../assets/img/p3/scooter_360_static.png),
                linear-gradient(to bottom, #{$teal} 0%, #{$teal} 70%, #{$gogoro-blue} 70%, #{$gogoro-blue} 100%);
            background-repeat: no-repeat;
            background-position: center bottom, center, bottom;

            @include sm() {
                background-size: cover;
            }
        }

        .wrapper {
            position: relative;
            padding-top: 0;
            min-height: 650px;
            z-index: 2;

            @include md() {
                padding-top: 55.5%;
                min-height: unset;
            }

            @include xs() {
                padding-top: 82.5%;
                min-height: unset;
            }
        }

        .scooter-showcase {
            background-image: url(../../assets/img/p3/scooter_360.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left calc(50% + 38px) bottom;
            width: 100%;
            max-width: 740px;
            padding-top: 100%;
            position: absolute;
            right: 0;
            left: 0;
            bottom: -10%;
            display: block;
            margin: auto;

            @include md() {
                padding-top: 91.5%;
                background-position: left 50% bottom;
            }

            @include xs() {
                padding-top: 146.5%;
            }
        }
    }

    .steps-caption {
        background: $gogoro-blue;
        background: var(--gogoro-blue);
        color: $yellow;
        color: var(--yellow);
        max-width: 1440px;
        padding-left: 8.333333333%;
        padding-right: 8.333333333%;

        @include sm() {
            margin: 0 0 58px 0;
        }

        &::before {
            background: $gogoro-blue;
            background: var(--gogoro-blue);
        }
    }

    .steps {
        background: $gogoro-blue;
        background: var(--gogoro-blue);
        position: relative;
        z-index: 0;

        @include sm() {
            padding: 0 0 80px 0;
        }

        &::before {
            background: $gogoro-blue;
            background: var(--gogoro-blue);
            z-index: 2;
        }

        .wrapper {
            padding-left: 0;
            padding-right: 0;
        }

        &.is-mobile {
            .step {
                height: auto;

                &.step-1 {
                    margin-top: 0;
                }

                .step-number {
                    transform: translateY(250px);
                }
            }
        }

        .step {
            height: 90vh;
            position: relative;
            z-index: 1;
            overflow: visible;

            @media (max-width: 1564px) {
                overflow: hidden;
            }

            @include sm() {
                height: auto;
            }

            @include sm() {
                height: unset;
                padding-bottom: 80px;

                &:last-child {
                    padding-bottom: 0;
                }
            }

            &.step-1,
            &.step-3 {
                .step-number {
                    @include sm() {
                        left: -5px;
                    }
                }
            }

            &.step-1 {
                .step-number {
                    left: -137px;
                }

                .step-video-wrapper {
                    &+.description .step-number {
                        left: 0;

                        @include sm() {
                            transform: translateY(124px);
                        }

                        @include sm() {
                            transform: translateY(-99px);
                        }
                    }
                }
            }

            &.step-2 {
                .step-number {
                    right: -176px;
                    left: unset;
                }

                .step-video-wrapper {
                    &+.description .step-number {
                        left: -5px;
                        right: unset;

                        @include sm() {
                            transform: translateY(124px);
                        }

                        @include sm() {
                            transform: translateY(-119px);
                        }
                    }
                }
            }

            &.step-3 {
                .step-number {
                    left: -171px;
                }

                .step-video-wrapper {
                    &+.description .step-number {
                        left: -5px;

                        @include sm() {
                            transform: translateY(148px);
                        }

                        @include sm() {
                            transform: translateY(-99px);
                        }
                    }
                }
            }

            &.step-1 {
                margin-top: -80vh;

                @include sm() {
                    margin-top: 0;
                }
            }

            &.step-2 {
                .description {
                    left: auto;

                    @include sm() {
                        right: unset;
                        left: -5px;
                    }
                }

                .description {
                    right: 8.333333333%;

                    @include sm() {
                        right: unset;
                    }
                }
            }

            .step-number,
            .description {
                @include flex-center();
                align-items: flex-start;
                flex-direction: column;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;

                @include sm() {
                    display: block;
                }
            }
            .step-number {
                font-family: "TTNormsPro-Bold", $global-font-family;
                color: #173e6f;
                font-size: 500px;
                opacity: .2;
                transition-property: transform;
                transition-duration: .5s;
                transition-timing-function: cubic-bezier(0, 0, .2, 1);
                z-index: -1;

                @include sm() {
                    font-size: 220px;
                    line-height: 1;
                    top: unset;
                    bottom: unset;
                    left: unset;
                    transform: translateY(-88px);
                }
            }

            .step-video-wrapper + .description {
                position: static;
                padding: 60px 24px;
                text-align: center;
                width: 100%;
            }

            .description {
                color: $white;
                color: var(--white);
                max-width: 384px;
                width: 27vw;
                margin: 0 auto;
                left: 8.333333333%;
                overflow: visible;

                @include md() {
                    max-width: 560px;
                }

                @include sm() {
                    position: static;
                    padding: 30px 20px 0 20px;
                    text-align: center;
                    width: 100%;
                }
            }

            .step-caption {
                margin: 0 0 20px 0;
                transition-property: transform, filter;
                transition-duration: .5s;
                transition-timing-function: cubic-bezier(0, 0, .2, 1);
                word-wrap: break-word;
                width: 100%;

                @include sm() {
                    filter: opacity(1);
                    margin: 0 0 14px 0;
                }

            }

            .step-content {
                @include sm() {
                    line-height: 1.57;
                    filter: opacity(1);
                }
            }

            .step-content {
                font-size: 20px;
                margin: 0 0 40px 0;
                line-height: 1.4;
                transition-property: transform, filter;
                transition-duration: .5s;
                transition-timing-function: cubic-bezier(0, 0, .2, 1);
                word-wrap: break-word;
                width: 100%;

                &:last-child {
                    margin: 0;
                }

                @include sm() {
                    font-size: 14px;
                    margin: 0;
                }
            }
        }

        .animation-wrapper {
            height: calc(100vh - #{$desktop-header-height});
            margin: auto;
            top: $desktop-header-height;
            position: sticky;
            overflow: hidden;
            z-index: 1;

            @include sm() {
                display: none;
            }
        }

        .step-video {
            border-radius: 20px;
            position: absolute;
            left: 0;
            right: 0;
            top: 120px;
            bottom: 0;
            width: 180px;
            margin: auto;
            z-index: -1;
            transform: translateY(-86px) scale(1.4);
            opacity: 1;

            @include md() {
                width: 18vw;
                top: 12.5vw;
            }

            @media (max-width: 900px) {
                transform: translateY(-9.2vw) scale(1.4);
            }
            @media (max-width: 894px) {
                transform: translateY(-8.7vw) scale(1.4);
            }
            @media (max-width: 820px) {
                transform: translateY(-7.7vw) scale(1.4);
            }


            &.hide {
                z-index: -9999;
            }
        }

        .step-video-wrapper {
            background-image: url(../../assets/img/p2/img-phone-hand-mobile.png);
            background-size: 1105px 650px;
            height: 650px;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;

            @include lg() {
                background-size: 1105px 650px;
                height: 650px;

                .step-video {
                    top: 0;
                    transform: translateY(-79px);
                    width: 214px;
                }
            }

            @include sm() {
                background-size: 1105px 650px;
                height: 650px;

                .step-video {
                    transform: translateY(-79px);
                    width: 214px;
                }
            }

            @include xs() {
                background-size: 768px 450px;
                height: 450px;

                .step-video {
                    transform: translateY(-56px);
                    width: 150px;
                }
            }
        }

        .smile-face {
            width: 360px;
            height: 360px;
            position: absolute;
            top: -2%;
            bottom: 0;
            left: -10%;
            right: 0;
            margin: auto;
            transition: transform .5s cubic-bezier(0, 0, .2, 1), opacity .5s cubic-bezier(0, 0, .2, 1);
            opacity: 0;
            z-index: -1;

            @include md() {
                display: none;
            }
        }

        .holding-cellphone {
            background-image: url(../../assets/img/p2/img-phone-hand.png);
            background-position: center;
            background-size: 1000px 540px;
            background-repeat: no-repeat;
            margin: auto;
            display: block;
            position: absolute;
            top: 120px;
            left: 0;
            right: 0;
            height: 100%;
            bottom: 0;
            width: 1000px;
            transform: scale(1.4);

            @include md() {
                background-size: 100vw 54vw;
                width: 100vw;
            }
        }
    }
}

.ie,
.edge {
    &.home {
        .steps .step-video-wrapper .step-video {
            top: calc(32px + #{$desktop-header-height});
            height: 60vh;
        }
    }
}
