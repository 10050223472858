@import "../../scss/setup/variable.scss";
@import "../../scss/mixins/media-query.scss";
@import "../../scss/mixins/flex-center.scss";

.about-us {
    .standard-container:not(.hero-about-us):not(.footer):not(.header):not(.promotion) {
        .wrapper {
            padding: 180px 96px;

            @include md() {
                padding: 90px 5.066666667%;
            }
            @include sm() {
                padding: 90px 24px;
            }
        }
    }

    .header {
        background: $teal;
        background: var(--teal);
        position: sticky;
    }

    .statement {
        max-width: 996px;
        margin: 0 auto;
        font-size: 40px;
        line-height: 1.25;

        @include md() {
            font-size: 24px;
        }
    }

    .section {
        overflow: hidden;
        @include flex-center();

        .wrapper {
            @include md() {
                padding: 80px 0;
            }
        }
    }

    .hero-about-us {
        background: $teal;
        background: var(--teal);

        .wrapper {
            @include flex-center();
            justify-content: flex-start;
            position: relative;
            height: 100%;
        }

        .hero-image {
            position: absolute;
            top: 0;
            right: 175px;

            @include md() {
                margin: auto;
                width: 80%;
                top: 87px;
                left: 0;
                right: 0;
            }
        }

        .hero-statement-wrap {
            max-width: 996px;
            width: 100%;
            margin: 0 auto;
            @include sm() {
                max-width: 360px;
            }
        }

        .hero-statement {
            color: $blue-dark;
            color: var(--blue-dark);
            position: relative;
            font-family: "TTNormsPro-Bold", $global-font-family;
            font-size: 110px;
            line-height: 1;
            padding: 180px 0;
            width: 100%;
            max-width: 640px;

            @include sm() {
                font-size: 56px;
            }
        }
    }

    .vision {
        background-image: url(../../assets/img/p2/img-about-ourmission-bg.svg);
        background-repeat: no-repeat;
        background-position: center bottom 116px;
        background-size: 100% auto;

        @include md() {
            background-position: center bottom 0;
        }

        .wrapper {
            @include flex-center();
        }

        .statement {
            color: $gogoro-blue;
            color: var(--gogoro-blue);
        }
    }

    .achievement {
        background: #173e6f;
        color: #fff;

        .wrapper {
            @include flex-center();
            flex-direction: column;
        }

        .statement {
            margin: 0 0 60px 0;

            @include md() {
                margin: 0 0 30px 0;
            }
        }

        .head-of-goshare {
            max-width: 996px;
            margin: 0 auto;
            color: $teal;
            color: var(--teal);
            display: block;
            font-style: normal;
            width: 100%;
        }
    }

    .features {
        @include flex-center();
        flex-wrap: wrap;

        .feature-wrapper {
            @include flex-center();

            &.reverse {
                flex-direction: row-reverse;
            }

            @include md() {
                flex-direction: column-reverse;

                &.reverse {
                    flex-direction: column-reverse;
                }
            }
        }

        .reverse {
            .col {
                &:nth-child(odd) {
                    padding-left: 0;
                    padding-right: calc(50vw - #{$screen-max-desktop} / 2);
                }

                &:nth-child(even) {
                    padding-left: calc(50vw - #{$screen-max-desktop} / 2);
                    padding-right: 0;
                }
            }
        }

        .col {
            @include flex-center();
            flex-direction: column;
            overflow: hidden;
            width: 50%;

            &:nth-child(odd) {
                padding-left: calc(50vw - #{$screen-max-desktop} / 2);
                padding-right: 0;
            }

            &:nth-child(even) {
                padding-left: 0;
                padding-right: calc(50vw - #{$screen-max-desktop} / 2);
            }

            @include md() {
                width: 100vw;
                text-align: center;
                height: auto;
            }
        }

        .feature-wrapper {
            .col {
                &.description {
                    @include md() {
                        padding: 96px 0;
                        padding-left: 5.066666667%;
                        padding-right: 5.066666667%;
                    }
                    @include sm() {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }
            }
        }

        .caption {
            color: $gogoro-blue;
            color: var(--gogoro-blue);
            padding: 0 90px;
            margin: 0 0 20px 0;
            width: 100%;

            @include md() {
                margin: 0 0 14px 0;
                font-size: 28px;
                font-weight: 500;
                line-height: 1.21;
                padding: 0;
            }
        }

        .content {
            background: #fafafa;
            font-size: 20px;
            line-height: 1.4;
            padding: 0 90px;
            width: 100%;

            @include md() {
                padding: 0;
                font-size: 14px;
                line-height: 1.57;
            }
        }

        .graphic {
            img,
            picture {
                max-width: 100%;
                vertical-align: bottom;
            }
        }

        .scooter {
            background: $teal;
            background: var(--teal);
        }

        .energy-network {
            background: $green;
            background: var(--green);
        }

        .mobility {
            background: #3CCAFD;
        }
    }
}

.ie,
.edge {
    &.about-us {
        .header {
            position: fixed;
        }

        .hero-about-us {
            margin-top: $desktop-header-height;
        }

        .vision,
        .achievement {
            min-height: auto;
            padding: $desktop-header-height 0;

            .wrapper {
                min-height: auto;
            }
        }
    }
}

.zh .about-us {
    .hero-statement-wrap {
        @include md() {
            max-width: 314px;
        }
    }
    .hero-about-us .hero-statement {
        font-size: 100px;
        font-weight: 700;
        line-height: 1.1;
        max-width: 610px;

        @include md() {
            font-size: 52px;
        }
    }
    .statement {
        line-height: 1.4;
    }

    .features .content {
        line-height: 1.6;
    }

    .spec-text-nowrap {
        white-space: nowrap;

        @include sm() {
            white-space: initial;
        }
    }
}
